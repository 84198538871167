import React from 'react';
import {Link} from 'gatsby';

export default function Tags({tags}) {
  return (
    <ul className="post-show__tags tags">
      {tags.sort().map((tag) => (
        <li key={tag}>
          <Link
            className={`tags__item tags-${tag.toLowerCase()}`}
            to={`/tags/${tag}`}
          >
            {tag.toLowerCase()}
          </Link>
        </li>
      ))}
    </ul>
  );
}
