import {graphql} from 'gatsby';
import React from 'react';
import {Helmet} from 'react-helmet';
import Layout from '../components/layout';
import PostMeta from '../components/post-meta';
import Tags from '../components/tags';
import {useSiteMetadata} from '../utils/use-site-metadata';

export default function Template({data}) {
  const post = data.markdownRemark;
  const {title, description, siteUrl} = useSiteMetadata();

  return (
    <Layout variant="small">
      <Helmet>
        <title>
          {post.frontmatter.title} — {title} · {description}
        </title>
        <meta property="og:title" content={post.frontmatter.title} />
        <meta property="og:description" content={post.excerpt} />
        <meta
          property="og:url"
          content={`${siteUrl}${post.frontmatter.path}/`}
        />
      </Helmet>

      <div className="post-show">
        <header>
          <Tags tags={post.frontmatter.tags} />

          <h1 className="post-show__title">{post.frontmatter.title}</h1>

          <PostMeta post={post} />
        </header>

        <div
          className="post-show__content markdown-render"
          dangerouslySetInnerHTML={{__html: post.html}}
        />
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: {path: {eq: $path}}) {
      html
      excerpt
      frontmatter {
        author
        date
        shortDate: date(formatString: "MMMM Do, YYYY")
        path
        tags
        title
      }
    }
  }
`;
